import React from 'react';
import { Box, Container } from '@mui/material';

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const Layout = ({ children }) => {
  if(is_mobile_device()){
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm">
          {children}
        </Container>
      </Box>
    );
  }
  else {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="md">
          {children}
        </Container>
      </Box>
    );
  }
};

export default Layout;