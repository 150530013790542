import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Grid, Link, Badge, Button, Popover, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const UpdateLog = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid item xs={4} container justifyContent="flex-end">
        <IconButton color="secondary" onClick={handleClick}>
          <DescriptionIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <Typography sx={{ p: 2, whiteSpace: 'pre-line' }}>
          {`V0.3.0:
            - Messages will now get deleted after 3 days.
            - Created a discord server for feedback.
            - Added a update log(as you can see)
          `}
        </Typography>
      </Popover>
    </Grid>
  );
};

const Header = () => {

  const is_mobile = is_mobile_device();

  if(is_mobile_device()){
    return (
      <AppBar position="static">
        <Toolbar>
        <Badge color="secondary" style={{left: "10px"}} badgeContent={"BETA"}></Badge>
          <Grid container alignItems="center">
            <Grid item xs={12} container justifyContent="center">
              <Link href="/" color="inherit" underline="none">
                <Typography color="secondary" variant="h6" component="div">privy.tools</Typography>
              </Link>
            </Grid>
          </Grid>
            <Grid item xs={0.01} container justifyContent="center">
              <UpdateLog />
            </Grid>
        </Toolbar>
      </AppBar>
    );
  }
  else {
    return (
      <AppBar position="static">
        <Toolbar>
        <Badge color="secondary" style={{left: "190px"}} badgeContent={"BETA"}></Badge>
        <Grid container alignItems="center">
            <Grid item xs={4}>
              <Link href="/" color="inherit" underline="none">
                <Typography color="secondary" variant="h6" component="div" letterSpacing={7.0}>privy.tools</Typography>
              </Link>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <Typography color="secondary" variant="h6" component="div">The correct procedure for shipping sensitive data.</Typography>
            </Grid>
            <UpdateLog />
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
};

export default Header;