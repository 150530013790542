import {React, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';

import ShareableLink from './components/shareable_link';
import Layout from './components/layout';
import Header from './components/header';
import Footer from './components/footer';
import SelfDestructingMessage from './components/self_destructing_message';
import Maintenance from './components/maintrance_page';

import { retrieveMessage, isMaintenance } from './utils/backend_api';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SelfDestructingMessageWrapper = () => {
  const [messageContent, setMessageContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [backendWorks, setbackendWorks] = useState(false);
  const random_string = useParams();

  useEffect(() => {
    const fetchMessagePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await retrieveMessage(random_string.random_string);

        if(response === "ERR_NETWORK" || response === "ECONNREFUSED"){
          setbackendWorks(false);
          reject(new Error('Backend is down!'));
        }
        else if (response) {
          setMessageContent(response);
          setbackendWorks(true);
          resolve('Message retrieved successfully!');
        } else {
          setbackendWorks(true);
          reject(new Error('Message not found!'));
        }

      } catch (error) {
        reject(new Error('Unkown Error, Contact a developer!'));
        setbackendWorks(false);
      } finally {
        //setbackendWorks(true);
        setIsLoading(false);
      }
    });

    toast.promise(
      fetchMessagePromise,
      {
        pending: 'Fetching message...',
        success: 'Message retrieved successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );

    fetchMessagePromise.catch((error) => console.error('Error fetching message:', error));
  }, [random_string]);

  if(backendWorks){
    if (!messageContent && !isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>Message was not found!</h1>
          <h2>Contact the sender of this message directly to send this message again.</h2>
          {/* {window.location.href = '/'} */}
        </div>
      );
    }
    else if(messageContent && !isLoading){
      return <SelfDestructingMessage message={messageContent} id={random_string.random_string} />;
    }
  }
  else {
    if(isLoading){
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'white' }}>Loading...</h1>
        </div>
      );
    }
    else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ color: 'red' }}>Backend Error!</h1>
          <h2>Contact the site owner to resolve this issue.</h2>
        </div>
      );
    }
  }
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const App = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(null);

  if(isMaintenanceMode === null){
    const checkMaintenancePromise = new Promise(async (resolve, reject) => {
      try {
        const maintenanceMode = await isMaintenance();
        if(maintenanceMode === "ERR_NETWORK" || maintenanceMode === "ECONNREFUSED"){
          setIsMaintenanceMode(maintenanceMode);
          reject(new Error('Service is down!'));
        }
        else if(!maintenanceMode){
          setIsMaintenanceMode(false);
          resolve();
        }
        else if(maintenanceMode){
          setIsMaintenanceMode(true);
          reject(new Error('Service is under maintenance!'));
        }
      } catch (error) {
        reject(new Error('Unknown Service Error!'));
      }
    });

    toast.promise(
      checkMaintenancePromise,
      {
        // pending: 'Connecting to Service...',
        // success: 'privy.tools Loaded!!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
      ,{autoClose: 1000}
    );
}

  if(isMaintenanceMode === false){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <Header />
          <Layout>
            <Routes>
              <Route path="/" element={<ShareableLink />} />
              <Route path="/message/:random_string" element={<SelfDestructingMessageWrapper />} />
            </Routes>
          </Layout>
          <ToastContainer theme="dark" />
          <Footer />
        </Router>
        </ThemeProvider>
      );
  }
  else if(isMaintenanceMode === null){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ color: 'white' }}>Connecting..</h1>
          </div>
        </Layout>
        <Footer />
      </ThemeProvider>
    );
  }
  else if (isMaintenanceMode === "ERR_NETWORK" || isMaintenanceMode === "ECONNREFUSED") {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ color: 'red' }}>Service is down!</h1>
            <h2>Please check back later.</h2>
          </div>
        </Layout>
        <ToastContainer theme="dark" />
        <Footer />
      </ThemeProvider>
    );
  }
  else if(isMaintenanceMode === true){
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Layout>
          <Maintenance/>
        </Layout>
        <ToastContainer theme="dark" />
        <Footer />
      </ThemeProvider>
    );
  }
};

export default App;