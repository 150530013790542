import React, { useState } from 'react';
import { createMessage } from '../utils/backend_api';
import { generate_random_string } from '../utils/util';
import { TextField, Button, Typography } from '@mui/material';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

/*
to implement:
  -Maintrance mode
  -Delete message after 3 days
  -Update log
  -Discord icon
*/

const ShareableLink = () => {
  const [message, set_message] = useState('');
  const [link, set_link] = useState('');
  const [user_done_input, set_user_done_input] = useState(false);

  const generateRandomKey = () => {
    return CryptoJS.lib.WordArray.random(16).toString();
  };
  
  const encryptMessage = (message, key) => {
    return CryptoJS.AES.encrypt(message, key).toString();
  };

  const message_failed_to_create = () => {
    return (
      <div>
        <Typography variant="h4" style={{ color: "red" }}>Failed to create message, please contact the site owner!</Typography>
      </div>
    );
  };

  /*
    todo: add Loading while its doing the request 
  */
  const handle_generate_link = async () => {
    const random_string = generate_random_string();
    const generated_key = generateRandomKey();
    const encrypted_message = encryptMessage(message, generated_key);
    
    const createMessagePromise = new Promise(async (resolve, reject) => {
      try {
        const response = await createMessage(random_string, encrypted_message);
        if(response === "ERR_NETWORK" || response === "ECONNREFUSED"){
          reject(new Error('Failed to create message, Backend is down!'));
        }
        else if (!response) {
          reject('Failed to create message!');
        }
        else {
          const generated_link = `${window.location.origin}/message/${random_string}?key=${generated_key}`;
          set_link(generated_link);
          resolve('Message created successfully!');
        }
      } catch (error) {
        reject('Failed to create message!');
      }
    });
  
    toast.promise(
      createMessagePromise,
      {
        pending: 'Creating message...',
        success: 'Message created successfully!',
        error: {
          render({ data }) {
            return data.message;
          }
        }
      }
    );
  
    createMessagePromise.catch(() => message_failed_to_create());
  };

  //Mobile view
  if(is_mobile_device()){
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TextField
          label="Enter your message"
          color='secondary'
          autoComplete='off'
          variant="outlined"
          error={user_done_input && message.length === 0}
          helperText={(message.length === 0 && user_done_input) ? 'Message cannot be empty' : ''}
          autoFocus={true}
          value={message}
          onChange={(e) => {
            set_message(e.target.value);
            set_user_done_input(true);
          }}
          style={{ margin: '10px', width: '300px' }}
        />
        <text style={{color:'grey' }}>Unread messages are automatically deleted after 3 days and cannot be recovered.</text>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handle_generate_link} 
          style={{ margin: '10px' }}>
          Generate Link
        </Button>
        {link && 
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              label="Shareable Link"
              color='secondary'
              variant="standard"
              value={link}
              InputProps={{ readOnly: true}}
              style={{ width: '140%',right: '10px', height: '60px'}}
            />
            <Button 
              variant="contained" 
              color="info" 
              onClick={() => {
                navigator.clipboard.writeText(link);
                toast.success('Link copied to clipboard');
              }}>
              Copy
            </Button>
          </div>
        }
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TextField
        label="Enter your message"
        color='secondary'
        autoComplete='off'
        variant="outlined"
        error={user_done_input && message.length === 0}
        helperText={(message.length === 0 && user_done_input) ? 'Message cannot be empty' : ''}
        autoFocus={true}
        value={message}
        onChange={(e) => {
          set_message(e.target.value);
          set_user_done_input(true);
        }}
        style={{ margin: '10px', width: '800px' }}
      />
      <text style={{color:'grey' }}>Unread messages are automatically deleted after 3 days and cannot be recovered.</text>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handle_generate_link} 
        style={{ margin: '10px' }}>
        Generate Link
      </Button>
      {link && 
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Shareable Link"
            color='secondary'
            variant="standard"
            value={link}
            InputProps={{ readOnly: true}}
            style={{ margin: '10px', width: '500px', height: '100px'}}
          />
          <Button 
            variant="outlined" 
            color="info" 
            onClick={() => {
            navigator.clipboard.writeText(link);
              toast.success('Link copied to clipboard');
            }}
            style={{ margin: '10px', marginBottom: '50px' }}>
            Copy
          </Button>
        </div>
      }
    </div>
  );
};

export default ShareableLink;