import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Grid, Link, Badge } from '@mui/material';

function is_mobile_device() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1);
}

const Maintenance = () => {

  const is_mobile = is_mobile_device();

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h3" style={{ color: "red", textDecoration: "underline", marginBottom: "100px"}}>We're currently under maintenance!</Typography>
        <Typography variant="h4" style={{ color: "white", textAlign: 'center' }}>Please check back later!</Typography>
        <Typography variant="h5" style={{ color: "yellow", marginTop: '20px' }}>I apologize for any inconvenience caused.</Typography>
    </div>
  );
};

export default Maintenance;